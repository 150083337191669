import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentBox from "../components/content-box"
import PageHeader from "../components/headers/page-header"
import SectionHeader from "../components/headers/section-header"
import { css } from "@emotion/core"
import { Link, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBitcoin,
  faEthereum,
} from "@fortawesome/free-brands-svg-icons"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { AvailableOn } from "../components/available-on"
import Credits from "../components/credits/Credits"
import { SocialMedia } from "../components/social-media"

class PageAbout extends React.Component {
  render() {

    const { data } = this.props

    return (
      <Layout location={this.props.location}>
        <SEO
          title={'About'}
        />

        <PageHeader>About</PageHeader>
        <ContentBox>
          <p css={css`
						text-align: center;
						font-size: 18px;
						margin: 10px 20px 30px;
						
						@media screen and (min-width: 960px) {
              font-size: 24px;
              margin: 20px 40px 30px;
						}
					`}>Established 2012, <strong>Paranoid American Inc</strong> was founded to create comics, graphic novels and
            interactive
            content
            exploring research into conspiracy theories and the occult.</p>

          <div
            css={css`
              display:flex;
              flex-direction:row;
              flex-wrap:wrap;
              justify-content:space-around;
              
              > div {
                flex: 0 100%;
                h3 { text-align: left; }
                @media screen and (min-width: 960px) {
                  flex: 0 50%;
                  h3 { text-align: center; }
                  p { margin: 0 20px 20px; }
                }
              }
            `}>
            <div>
              <SectionHeader>Conspiracy Theories</SectionHeader>
              <p>
                <Link to={'/research/mind-control'} title={'mind control'}>Mind control</Link>, <Link to={'/research/illuminati'} title={'illuminati'}>illuminati</Link>, <Link to={'/research/freemasons'} title={'freemasons'}>Freemasons</Link>, <Link to={'/research/chemtrails'} title={'chemtrails'}>chemtrails</Link>, and the <Link to={'/research/new-world-order'} title={'new world order'}>new world order</Link>. You may or may not believe <Link to={'/research/jfk-assassination'} title={'jfk assassination'}>JFK was assassinated by the CIA</Link>, or that <Link to={'/research/9-11'} title={'9/11'}>9/11 was an “inside job.”</Link> But these aren’t just conspiracy theories
                anymore, they represent modern American mythology.
              </p>
            </div>

            <div>
              <SectionHeader>Occult Knowledge</SectionHeader>
              <p>We’re interested in the lesser known topics of history. <Link to={'/research/sacred-geometry'} title={'sacred geometry'}>Sacred geometry</Link>. <Link to={'/research/memory'} title={'memory techniques'}>Ancient memory techniques</Link>. <Link to={'/research/secret-societies'} title={'mystery cults'}>Mystery Cults</Link>. Hermetic tradition. Satanic Panic. Nothing is too old, dusty, sacred, secret, or hidden for our
                stories. In fact, the more unknown, the better.</p></div>

            <div>
              <SectionHeader>100% Spandex Free</SectionHeader>
              <p>Paranoid American features characters that make other superheroes and villains look as tacky as their
                spandex onesies when compared to their real-world inspirations and counterparts. We firmly believe that
                fact
                is more interesting than fiction, and proving it with our stories.</p></div>

            <div>
              <SectionHeader>Comics and Games</SectionHeader>
              <p>If this stuff is so important, why use comic books and video games as the medium? There’s enough books
                and
                documentaries with gloom and doom on these subjects out there. Injecting a little humor, animation, and
                interaction, makes everything more interesting.</p>
            </div>
          </div>
        </ContentBox>

        <a name={"support"} id={"support"}/>
        <PageHeader>Support</PageHeader>
        <ContentBox>
          <SectionHeader align={"left"}>How can you support us?</SectionHeader>
          <p>If you like what you see here, and would like to support us to continue making more, please consider
            sharing links to your favorite pages and issues on social media, online forums and friends.</p>

          <SocialMedia style={css`font-size:48px; a { border-bottom: none; padding: 3px;} `} />

          <SectionHeader align={"left"}>Buy Something!</SectionHeader>
          <p>It costs money to hire good artists and writers, host a website, print books and promote all of these
            projects. Please consider buying a comic, print, pin, sticker or t-shirt from the <Link
              to={"/shop"} title={'Paranoid American Shop'}>shop</Link>, or one of the following storefronts:
          </p>
          <AvailableOn comics/>

          <SectionHeader align={"left"}>Listen to Sound Scientists</SectionHeader>
          <p>The original inspiration of Time Samplers and eventually Paranoid American Inc, was a conceptual music
            group called <strong>Sound Scientists</strong>, and by simply listening to them on <OutboundLink
              target={"_blank"}
              href={"https://geo.music.apple.com/us/artist/sound-scientists/386387248?mt=1&app=music&ls=1"}>iTunes</OutboundLink>,{" "}
            <OutboundLink target={"_blank"}
                          href={"https://www.pandora.com/artist/sound-scientists/ARf2bK63gd6rJvk"}>Pandora</OutboundLink>,{" "}
            <OutboundLink target={"_blank"}
                          href={"https://open.spotify.com/artist/0gF1zIF8ZKYBThyozKBWcZ"}>Spotify</OutboundLink>,{" "}
            <OutboundLink target={"_blank"}
                          href={"https://www.amazon.com/Instrumentally-Sound-Scientists/dp/B003Z2SPBQ/"}>Amazon</OutboundLink>,{" "}
            or other popular streaming services you will be supporting the creative minds behind Paranoid
            American.</p>

          <div id={"players"} css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            > div {
              text-align: center;
              flex: 1;
              min-width: 300px;
              
              > a {
                display: block;
              }
              
              a {
                border-bottom: none !important;
              }
            }
          `}>
            <div>
              <OutboundLink
                target={"_blank"}
                href={"https://geo.music.apple.com/us/artist/sound-scientists/386387248?mt=1&app=music&ls=1"}
                css={css`
                display:block;
                overflow:hidden;
                background:url(https://linkmaker.itunes.apple.com/en-us/lockup.svg?kind=artist&bubble=apple_music&style=standard-black) no-repeat;
                width:140px;
                height:30px;
                margin:auto;
          `}/>

              <iframe allow={"autoplay *; encrypted-media *;"} title={"iTunes"}
                      css={css`width:100%;max-width:300px;overflow:hidden;background:transparent;`}
                      sandbox={"allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"}
                      src={"https://embed.music.apple.com/us/album/instrumentally-sound/386387247?app=music"}
                      height={"450"}
                      frameBorder={"0"}/>
            </div>

            <div>
              <OutboundLink target={"_blank"} href={"https://amzn.to/36YFMxy"}><Img
                fixed={data.amazonMusicLogo.childImageSharp.fixed}/></OutboundLink>
              <iframe id={"AmazonMusicEmbedB003YQ1M04"} title={"Amazon Music"}
                      src={"https://music.amazon.com/embed/B003YQ1M04/?id=lFpxdkWB5N&marketplaceId=A1F83G8C2ARO7P&musicTerritory=US"}
                      width={"300"} height={"450px"} css={css`border:1px solid rgba(0, 0, 0, 0.12);`}/>
            </div>

            <div>
              <OutboundLink target={"_blank"} href={"https://open.spotify.com/album/0LZQv669jz7YHaRR8varTl"}><Img
                fixed={data.spotifyLogo.childImageSharp.fixed}/></OutboundLink>
              <iframe src={"https://open.spotify.com/embed/album/0LZQv669jz7YHaRR8varTl"} title={"Spotify"}
                      width={"300"} height={"450"}
                      frameBorder={"0"} allowtransparency={"true"} allow={"encrypted-media"}/>
            </div>
          </div>
          <AvailableOn music/>

          <SectionHeader align={"left"}>Donate!</SectionHeader>
          <p>All donations are <em>greatly</em> appreciated, and better yet if you have an idea for a story or would
            just like to help fund a short story about a particular issue you find important please <Link to={'/about#contact'} title={'contact'}>contact us here</Link>.
          </p>
          <div css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            span {
            
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              border-bottom: none;
            
              svg {font-size:36px;display:block;}
              span { display:block; align-self: center; margin-left: 10px;}
            }
            
                          
              @media screen and (max-width: 360px) {
                justify-content: flex-start;
                span {
                  svg {font-size:18px;display:block;}
                  font-size: 0.78em;
                  span {margin-left: 6px;}
                }
              }
              @media screen and (max-width: 640px) {
                span {
                  svg {font-size:24px;display:block;}
                  font-size: 0.8em;
                  span {margin-left: 6px;}
                }
              }
            
          `}>
            <span>
              <FontAwesomeIcon icon={faBitcoin} title={"btc"}/>
              <span>1NsEu77QKBk76uPsEc7cU3vPYEhjR5Usf4</span>
            </span>
            <span>
              <FontAwesomeIcon icon={faEthereum} title={"eth"}/>
              <span>0xB7654972DD991317EF551c49D592a2dFA723c3C5</span>
            </span>
          </div>

        </ContentBox>

        <PageHeader>Artists & Writers</PageHeader>
        <ContentBox>
          <Credits credits={data.allPersonJson.edges}/>
        </ContentBox>

        <PageHeader>Submissions</PageHeader>
        <ContentBox>
          <p>Paranoid American is not currently accepting <em>unsolicited</em> submissions.</p>
          <p><strong>Why not?</strong> The main reason is to avoid unsolicited material from being sent in, then later
            claimed as uncredited use. The other reason is that unless we're looking for your message, submissions
            aren't
            reviewed regularly and likely won't be seen (and purged as spam after 30 days).</p>
          <p>However, if you have a <em>completed</em> original project that fits what
            we do, please send a brief synopsis (1-2 paragraphs max) to the <Link to={'/about#contact'} title={'contact'}>contact information below</Link>.</p>
        </ContentBox>

        <a name={"contact"} id={"contact"}/>
        <PageHeader>Contact</PageHeader>
        <ContentBox>
          <div
            css={css`
              display:flex;
              flex-direction:row;
              flex-wrap:wrap;
              justify-content: flex-start;
              
              > div {
                flex: 0 100%;
                @media screen and (min-width: 740px) {
                  flex: 0 50%;
                }
                @media screen and (min-width: 960px) {
                  flex: 0 33%;
                }
              }
            `}>
            <div>
              <SectionHeader align={"left"}>email</SectionHeader>
              <p><a
                href={"mailto:contact@paranoidamerican.com"}>contact@paranoidamerican.com</a>
              </p>
            </div>
            <div>
              <SectionHeader align={"left"}>mailing address</SectionHeader>
              <pre>
						    Paranoid American{"\n"}
                2860 Delaney Ave #560462{"\n"}
                Orlando, FL 32856{"\n"}
					    </pre>
            </div>
            <div>
              <SectionHeader align={"left"}>Social Media</SectionHeader>
              <p>Note: We rarely check or engage with social media. PR suicide, we know.</p>
              <SocialMedia style={css`font-size:30px; a { border-bottom: none; padding: 3px;} `} />
            </div>
          </div>
        </ContentBox>
      </Layout>
    )
  }
}

export default PageAbout

export const pageQuery = graphql`
    query {

        spotifyLogo: file(relativePath: { eq: "Spotify_Logo_RGB_Black.png" }) {
            childImageSharp {
                fixed(height: 30) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }

        amazonMusicLogo: file(relativePath: { eq: "amazon-music.png" }) {
            childImageSharp {
                fixed(height: 30) {
                    ...GatsbyImageSharpFixed_noBase64
                }
            }
        }

        allPersonJson {
            totalCount
            edges {
                node {
                    alternateName
                    name
                    website
                    image {
                        childImageSharp {
                            fluid(maxWidth: 200) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    description
                    sameAs
                }
            }
        }
    }
`